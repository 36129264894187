<template>
    <div>
        <div v-if="loading" class="card card-custom col-lg-13 col-xxl-12 mt-5">
            <Loader></Loader>
        </div>
        <div v-else>
            <Chat v-if="!showAllVisios && tuteur" :sessionId="classroom.id" :tuteur="true"></Chat>
            <div class="card card-custom gutter-b flex-row align-items-center justify-content-between p-5 mt-5">
                <div class="w-25">
                    <router-link :to="{ name: 'classrooms' }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            {{ !mobile() ? $t('CLASS.BACK') : '' }}
                        </span>
                    </router-link>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <h1 class="m-0 align-self-center w-100">{{ classroom.classe.title }}</h1>
                </div>
                <div v-if="!mobile()" class="w-25"></div>
            </div>
            <div v-if="!showAllVisios" class="d-flex">
                <div class="w-100">
                    <div class="d-flex justify-content-between align-items-center" :class="mobile() ? 'flex-column' : ''">
                        <!--Description de la classeroom-->
                        <div
                            class="card card-custom gutter-b d-flex flex-row flex-wrap justify-content-around align-items-center py-2 w-100"
                            :style="mobile() ? '' : 'height: 20rem;'"
                        >
                            <img
                                :src="classroom.classe.miniature"
                                ref="EditMiniature"
                                alt="text"
                                style="max-width: 60%; max-height: 100%; vertical-align: middle"
                                class="m-0 rounded"
                                :class="mobile() ? 'mb-10' : ''"
                            />
                            <div
                                style="min-width: 40%; max-height: 95%"
                                class="d-flex flex-column justify-content-center px-5"
                                :style="mobile() ? 'max-width:100%;' : 'max-width:55%;'"
                            >
                                <span class="font-weight-bold pb-2">{{ $t('CLASS.DESCRIPTION.LABEL') }}</span>
                                <p>{{ classroom.classe.description }}</p>
                                <p>
                                    <span class="font-weight-bold pb-2">{{ $t('CLASS.TUTOR.LABEL') }}</span>
                                    {{ classroom.tuteur.lastname.toUpperCase() }} {{ classroom.tuteur.firstname }}
                                </p>
                                <p>
                                    <span class="font-weight-bold pb-2">{{ $t('CLASS.DATE.LABEL') }}</span> Du
                                    <span class="font-weight-bold">{{ formatDate(classroom.start_date) }}</span> au
                                    <span class="font-weight-bold">{{ formatDate(classroom.end_date) }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--Boite à outils-->
                    <div class="card card-custom gutter-b p-2 align-items-center w-100">
                        <div class="w-100 h-100 mx-auto d-flex justify-content-around">
                            <b-button
                                v-if="!superviseur"
                                :disabled="!tuteur"
                                pill
                                variant="outline-primary"
                                :style="!tuteur ? 'cursor: not-allowed' : ''"
                                class="d-flex justify-content-center align-items-center"
                                v-b-modal.session-settings-modal
                            >
                                <b-icon icon="gear" class="mr-2"></b-icon>
                                Paramètre de la session
                            </b-button>
                            <b-modal
                                id="session-settings-modal"
                                title="Paramètre de la session"
                                ok-title="Confirmer"
                                cancel-title="Annuler"
                                @ok="updateClassroom()"
                                @hidden="resetClassroom()"
                            >
                                <template #default>
                                    <div v-if="errorUpdate" class="alert alert-danger">
                                        {{ errorUpdate }}
                                    </div>
                                    <b-form>
                                        <b-form-group
                                            id="visio_url"
                                            label="URL des vidéoconférences :"
                                            label-for="visio_url_input"
                                            invalid-feedback="L'URL de vidéoconférence doit commencer par https://meet.google.com/"
                                        >
                                            <b-form-input
                                                id="visio_url_input"
                                                v-model="classroomUpdate.visio_url"
                                                :state="classroomState.visio_url"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-form>
                                </template>

                                <template #modal-footer>
                                    <b-button variant="outline-secondary" @click="resetClassroom()">Annuler</b-button>
                                    <b-button variant="primary" @click="updateClassroom()">
                                        <b-spinner v-if="loadingUpdate" small variant="light"></b-spinner>
                                        <span v-else>Confirmer</span>
                                    </b-button>
                                </template>
                            </b-modal>
                            <span id="create-visio-button-wrapper" class="d-inline-block" tabindex="0" v-if="!superviseur">
                                <b-button
                                    pill
                                    variant="outline-primary"
                                    class="d-flex justify-content-center align-items-center"
                                    :disabled="!classroom.visio_url"
                                    :style="!classroom.visio_url ? 'cursor: not-allowed' : ''"
                                    v-b-modal.create-visio-modal
                                >
                                    <b-icon icon="plus" class="mr-2"></b-icon>
                                    Créer une vidéoconférence
                                </b-button>
                            </span>
                            <b-tooltip v-if="!classroom.visio_url" target="create-visio-button-wrapper" triggers="hover">
                                Vous devez d'abord renseigner l'URL des vidéoconférences dans les paramètres.
                            </b-tooltip>
                            <b-modal id="create-visio-modal" title="Créer une vidéoconférence" @hidden="resetDefaultVisio()">
                                <b-alert v-if="createError" variant="danger" show>
                                    {{ createError }}
                                </b-alert>
                                <form ref="form" @submit.stop.prevent="handleSubmit">
                                    <!--Nom-->
                                    <b-form-group
                                        :state="states.name"
                                        class="mb-0"
                                        invalid-feedback="Veuillez saisir un nom"
                                        label="Nom :"
                                        label-for="visioName"
                                    >
                                        <b-form-input
                                            id="visioName"
                                            v-model="defaultVisio.name"
                                            :state="states.name"
                                            placeholder="Nom de la vidéoconférence"
                                            required
                                            type="text"
                                        ></b-form-input>
                                    </b-form-group>

                                    <!--Date de début-->
                                    <label class="mt-3" for="visioStartDate">Date de début :</label>
                                    <b-form-datepicker
                                        id="visioStartDate"
                                        v-model="defaultVisio.startDate"
                                        :hide-header="true"
                                        :state="states.startDate"
                                        invalid-feedback="Veuillez saisir une date de début"
                                        placeholder="Date de début de la vidéoconférence"
                                        required
                                    ></b-form-datepicker>

                                    <!--Heure de début-->
                                    <label class="mt-3" for="visioStartTime">Heure de début :</label>
                                    <b-form-timepicker
                                        id="visioStartTime"
                                        v-model="defaultVisio.startTime"
                                        :hide-header="true"
                                        :state="states.startTime"
                                        invalid-feedback="Veuillez saisir une heure de début"
                                        locale="fr-FR"
                                        placeholder="Heure de début de la vidéoconférence"
                                        required
                                    ></b-form-timepicker>

                                    <!--Durée-->
                                    <b-form-group
                                        :state="states.duration"
                                        class="mt-3 mb-0"
                                        invalid-feedback="Veuillez saisir une durée positive"
                                        label="Durée :"
                                        label-for="visioDuration"
                                    >
                                        <b-input-group append="minutes">
                                            <b-form-input
                                                id="visioDuration"
                                                v-model="defaultVisio.duration"
                                                :state="states.duration"
                                                placeholder="Durée de la vidéoconférence"
                                                required
                                                type="number"
                                            ></b-form-input>
                                        </b-input-group>
                                    </b-form-group>

                                    <!--Compte dans le temps de formation-->
                                    <div class="d-flex align-items-center mt-3">
                                        <b-form-checkbox
                                            id="visioTrainingTime"
                                            v-model="defaultVisio.training_time"
                                            class="m-0"
                                            placeholder="Compte dans le temps de formation"
                                            required
                                            type="checkbox"
                                        ></b-form-checkbox>
                                        <label class="m-0" for="visioTrainingTime">Compte dans le temps de formation</label>
                                    </div>
                                </form>
                                <template #modal-footer>
                                    <b-button
                                        class="float-right"
                                        pill
                                        variant="outline-danger"
                                        @click="$bvModal.hide('create-visio-modal'), (sending = false), resetStates()"
                                    >
                                        Annuler
                                    </b-button>
                                    <b-button class="float-right" pill variant="outline-primary" @click="createVisio()">
                                        <p v-if="!sending" class="m-0">Créer</p>
                                        <b-spinner v-else label="Spinning" small variant="light"></b-spinner>
                                    </b-button>
                                </template>
                            </b-modal>
                            <b-button
                                v-if="!superviseur"
                                pill
                                variant="outline-primary"
                                class="d-flex justify-content-center align-items-center"
                                @click="$bvModal.show('start-classroom-modal')"
                                :disabled="classroom.started === 1 || !tuteur"
                                :style="!tuteur ? 'cursor: not-allowed' : ''"
                            >
                                <b-icon v-if="!activatingSession" icon="play" class="mr-2"></b-icon>
                                <b-spinner v-else small variant="light" class="mr-3"></b-spinner>
                                {{ classroom.started === 1 ? 'Session démarrée' : 'Démarrer la session' }}
                            </b-button>
                            <b-modal
                                id="start-classroom-modal"
                                title="Démarrer la session"
                                ok-title="Confirmer"
                                cancel-title="Annuler"
                                @ok="startClassroom()"
                            >
                                <p>Êtes vous sûr de vouloir démarrer la session ?</p>
                            </b-modal>
                            <b-button
                                class="d-flex justify-content-center align-items-center"
                                pill
                                variant="outline-primary"
                                @click="showAllVisios = true"
                            >
                                <b-icon class="mr-2" icon="person"></b-icon>
                                Liste des visios ({{ classroom.videoconferences.length }})
                            </b-button>
                        </div>
                    </div>
                    <!--Visios-->
                    <div class="card card-custom gutter-b p-5 align-items-center w-100">
                        <div class="w-100 h-100 mx-auto d-flex flex-column align-items-center">
                            <Visios
                                :key="key"
                                @reloadVisios="getClassroom()"
                                @showAllVisio="updateparent"
                                :videoconferences="classroom.videoconferences"
                            ></Visios>
                        </div>
                    </div>
                    <div :class="mobile() ? 'flex-column' : ''" class="d-flex justify-content-between align-items-center">
                        <!--Liste des utilisateurs inscrits à la session-->
                        <div class="card card-custom gutter-b p-5 align-items-center w-100">
                            <div class="w-100 h-100 mx-auto d-flex flex-column">
                                <h4>Liste des stagiaires</h4>
                                <b-table :fields="fields" :items="classroom.stagiaires" hover responsive>
                                    <template #cell(infos.CNAPS)="row">
                                        <p class="m-0" v-if="row.item.infos.CNAPS">{{ row.item.infos.CNAPS }}</p>
                                        <p class="m-0" v-else>Non renseigné</p>
                                    </template>
                                    <template #cell(result.duration)="row">
                                        <p class="m-0">{{ convertTime(row.item.result.duration) }}</p>
                                    </template>
                                    <template #cell(progress)="row">
                                        <b-progress show-progress>
                                            <b-progress-bar :value="(row.item.result.totalFinished / row.item.result.total) * 100">
                                                <span>
                                                    <strong>{{ row.item.result.totalFinished }} / {{ row.item.result.total }}</strong>
                                                </span>
                                            </b-progress-bar>
                                            <div
                                                v-if="row.item.result.totalFinished === 0"
                                                class="d-flex justify-content-center align-items-center w-100"
                                            >
                                                <strong>{{ row.item.result.totalFinished }} / {{ row.item.result.total }}</strong>
                                            </div>
                                        </b-progress>
                                    </template>
                                    <template #cell(state)="row">
                                        <p v-if="row.item.state === 1" class="m-0">Non commencé</p>
                                        <p v-if="row.item.state === 2" class="m-0">En cours</p>
                                        <p v-if="row.item.state === 3" class="m-0">Terminé</p>
                                    </template>
                                    <template #cell(action)="row">
                                        <button
                                            v-if="!superviseur"
                                            class="btn btn-icon btn-circle btn-light btn-sm mr-3"
                                            @click="downloadMarks(row.item)"
                                            v-b-tooltip.hover
                                            title="Télécharger le relevé de parcours individuel"
                                        >
                                            <b-icon
                                                :id="'iconReleve' + row.item.id"
                                                class="visible"
                                                icon="journal-bookmark-fill"
                                                variant="primary"
                                                style="height: 50%"
                                            ></b-icon>
                                            <b-spinner :id="'spinner' + row.item.id" class="hidden" small></b-spinner>
                                        </button>
                                        <router-link :to="{ name: 'classe', params: { classeUserId: row.item.id } }">
                                            <button class="btn btn-icon btn-circle btn-light btn-sm">
                                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                                    <inline-svg src="/media/svg/icons/General/Visible.svg" />
                                                </span>
                                            </button>
                                        </router-link>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VisiosList
                v-else
                :session="classroom"
                @reloadVisios="getClassroom()"
                @showAllVisio="updateparent"
                :tuteur="tuteur"
            ></VisiosList>
        </div>
    </div>
</template>
<script>
import { GET_CLASSROOM, UPDATE_CLASSROOM } from '../../core/services/store/api/classroom.service';
import moment from 'moment/moment';
import ApiService from '../../core/services/api.service';
import axios from 'axios';

export default {
    name: 'Classroom',
    components: {
        Visios: () => import('../content/components/Visios.vue'),
        VisiosList: () => import('../content/components/VisiosList.vue'),
        Loader: () => import('../layout/components/Loader.vue'),
        Chat: () => import('../content/components/Chat.vue')
    },
    data() {
        return {
            states: {
                name: null,
                startDate: null,
                startTime: null,
                duration: null
            },
            sending: false,
            key: 1,
            tuteur: false,
            superviseur: false,
            loading: true,
            classroom: {},
            showAllVisios: false,
            activatingSession: false,
            createError: null,
            defaultVisio: {
                name: '',
                startDate: '',
                startTime: '',
                duration: '',
                training_time: ''
            },
            classroomUpdate: {
                id: null,
                visio_url: ''
            },
            classroomState: {
                visio_url: null
            },
            loadingUpdate: false,
            errorUpdate: '',
            fields: [
                { key: 'infos.lastname', label: 'Nom', tdClass: 'align-middle' },
                { key: 'infos.firstname', label: 'Prénom', tdClass: 'align-middle' },
                { key: 'infos.CNAPS', label: 'Autorisation CNAPS', tdClass: 'align-middle' },
                { key: 'state', label: 'Statut', tdClass: 'align-middle' },
                { key: 'result.duration', label: 'Temps passé', tdClass: 'align-middle' },
                { key: 'progress', label: 'Leçons terminées', tdClass: 'align-middle' },
                {
                    key: 'action',
                    label: '',
                    thStyle: 'width: 8%',
                    tdStyle: 'width: 8%',
                    tdClass: 'align-middle text-center'
                }
            ]
        };
    },
    async mounted() {
        if (!this.$route.params.id) {
            this.$router.push({ name: 'dashboard' });
        } else {
            this.checkRights([1, 2]);
            await this.getClassroom();
        }
    },
    methods: {
        downloadMarks(classeUser) {
            const toggleVisibility = (element) => {
                element.classList.toggle('visible');
                element.classList.toggle('hidden');
            };

            const handleError = () => {
                this.$bvToast.toast('Une erreur est survenue lors du téléchargement du relevé de parcours individuel.', {
                    title: 'Erreur',
                    variant: 'danger',
                    solid: true
                });
            };

            const icon = document.getElementById('iconReleve' + classeUser.id);
            const spinner = document.getElementById('spinner' + classeUser.id);

            toggleVisibility(icon);
            toggleVisibility(spinner);

            axios({
                url: process.env.VUE_APP_API_URL + '/academy/classeUsers/releve/classeUser/' + classeUser.id,
                method: 'GET',
                responseType: 'blob'
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Bilan_' + classeUser.infos.lastname + '_' + classeUser.infos.firstname + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(handleError)
                .finally(() => {
                    toggleVisibility(spinner);
                    toggleVisibility(icon);
                });
        },
        checkFormValidity() {
            this.resetStates();
            const valid = this.$refs.form.checkValidity();

            this.states.name = this.defaultVisio.name !== '';
            this.states.startDate = this.defaultVisio.startDate !== '';
            this.states.startTime = this.defaultVisio.startTime !== '';
            this.states.duration = this.defaultVisio.duration !== '' && this.defaultVisio.duration >= 0;

            return Object.values(this.states).every((state) => state) && valid;
        },
        resetStates() {
            this.createError = null;
            this.states.name = null;
            this.states.startDate = null;
            this.states.startTime = null;
            this.states.duration = null;
        },
        convertTime(time) {
            //time est en seconde, convertie le en heure minute seconde
            let hours = Math.floor(time / 3600);
            let minutes = Math.floor((time - hours * 3600) / 60);
            let seconds = time - hours * 3600 - minutes * 60;
            // if hours = 0 on ne les affiche pas
            if (hours === 0) {
                hours = '';
            } else {
                hours = hours + 'h ';
            }
            return hours + minutes + 'm ' + seconds + 's';
        },
        createVisio() {
            this.sending = true;
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                this.sending = false;
                return;
            }
            this.resetStates();
            //Call API
            let data = {
                name: this.defaultVisio.name,
                tuteur_id: this.$store.getters.currentUser.id,
                start: this.defaultVisio.startDate + ' ' + this.defaultVisio.startTime,
                duration: this.defaultVisio.duration,
                training_time: this.defaultVisio.training_time,
                session_id: this.classroom.id
            };
            this.$store
                .dispatch('createVisio', data)
                .then(() => {
                    this.$bvToast.toast('La vidéoconférence a bien été créée.', {
                        title: 'Succès',
                        variant: 'success',
                        solid: true
                    });
                    this.getClassroom();
                    this.$nextTick(() => {
                        this.$bvModal.hide('create-visio-modal');
                    });
                    this.resetDefaultVisio();
                    this.sending = false;
                })
                .catch((error) => {
                    this.createError = error.error;
                    this.sending = false;
                });
        },
        resetDefaultVisio() {
            this.resetStates();
            this.defaultVisio.name = '';
            this.defaultVisio.startDate = '';
            this.defaultVisio.startTime = '';
            this.defaultVisio.duration = '';
            this.defaultVisio.training_time = 0;
        },
        startClassroom() {
            this.activatingSession = true;
            let data = {
                started: 1
            };
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/classroom/' + this.classroom.id, data).then(() => {
                this.$bvToast.toast('La session est maintenant accessible aux stagiaires.', {
                    title: 'Succès',
                    variant: 'success',
                    solid: true
                });
                this.getClassroom();
            });
        },
        updateparent(variable) {
            this.showAllVisios = variable;
        },
        mobile() {
            return window.screen.availWidth <= 992;
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        getClassroom() {
            this.$store.dispatch(GET_CLASSROOM, this.$route.params.id).then((response) => {
                //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                if (this.tuteur) {
                    if (
                        response.data.tuteur_id !== this.$store.getters.currentUser.id &&
                        this.$store.getters.currentUser.role !== 'admin'
                    ) {
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        this.loading = false;
                    }
                } else {
                    this.loading = false;
                }
                this.classroom = response.data;
                this.classroomUpdate.id = this.classroom.id;
                this.classroomUpdate.visio_url = this.classroom.visio_url;
                this.key = this.key + 1;
                this.activatingSession = false;
            });
        },
        resetClassroom() {
            this.classroomUpdate.visio_url = this.classroom.visio_url;
            this.errorUpdate = '';
            this.loadingUpdate = false;
            this.classroomState.visio_url = null;
            this.$bvModal.hide('session-settings-modal');
        },
        updateClassroom() {
            //vérifier que classroomUpdate.visio_url commence par https://meet.google.com/
            if (!this.classroomUpdate.visio_url.startsWith('https://meet.google.com/')) {
                this.classroomState.visio_url = false;
                return;
            } else {
                this.classroomState.visio_url = true;
            }

            this.loadingUpdate = true;
            this.errorUpdate = '';

            this.$store
                .dispatch(UPDATE_CLASSROOM, this.classroomUpdate)
                .then(() => {
                    this.$bvToast.toast('La session a bien été mise à jour.', {
                        title: 'Succès',
                        variant: 'success',
                        solid: true
                    });
                    this.getClassroom();
                    this.loadingUpdate = false;
                    this.$bvModal.hide('session-settings-modal');
                })
                .catch((error) => {
                    this.errorUpdate = error.error;
                    this.classroomUpdate.visio_url = this.classroom.visio_url;
                    this.loadingUpdate = false;
                });
        },
        checkRights(rightIds) {
            if (
                this.$store.getters.currentUser &&
                this.$store.getters.currentUser.rights &&
                this.$store.getters.currentUser.rights.academy
            ) {
                this.$store.getters.currentUser.rights.academy.forEach((right) => {
                    if (rightIds.includes(right.right_id)) {
                        if (right.right_id === 1) {
                            this.tuteur = true;
                        } else if (right.right_id === 2) {
                            this.superviseur = true;
                        }
                    }
                });
            }
        }
    }
};
</script>
<style scoped>
.visible {
    display: block !important;
}
.hidden {
    display: none !important;
}
</style>
